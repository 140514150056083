import "../../css/Repo.css";
import noteRepo from "../../assets/images/home/repositorio_institucional_01.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const RepoInv = () => {
  return (
    <div className="divRepo" data-tooltip-id="my-tooltip-inv">
      <a
        href="https://repositorio.ucsc.cl"
        target="__blank"
        className="hrefRepo"
      >
        <h4 className="text-center font-bold titleRepo text-[#fff]  ">
          Repositorio Institucional
        </h4>
        <div className="col d-flex justify-content-center">
          <img src={noteRepo} alt="" className="imgRepoInv mt-2" />
        </div>
        <div id="descRepos">
          <ReactTooltip
            id="my-tooltip-inv"
            className="tooltipRepos"
            place="bottom"
            variant="info"
            content="Plataforma digital destinada a disponibilizar la producción
            científica y académica de la Comunidad Universitaria UCSC,
            estandarizando la información, facilitando el acceso y preservando
            el conocimiento generado."
          />
        </div>
      </a>
    </div>
  );
};

export default RepoInv;
