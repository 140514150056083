import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Request.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import ChileanRutify from "chilean-rutify";
import Banner from "../../assets/images/banner/banner_request_work.png";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import {
  CCol,
  CFormInput,
  CFormTextarea,
  CRow,
  CForm,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";

const Workshop = () => {
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState();
  const [rut, setRut] = useState();
  const [f_taller, setFtaller] = useState();
  const [comentarios, setComentarios] = useState();
  const [talleres, setTalleres] = useState([]);
  const [taller, setTaller] = useState();
  const [presencial, setPresencial] = useState(1);
  const [ua, setUA] = useState();
  const [carreras, setCarreras] = useState([]);
  const [facultades, setFacultades] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const recaptcha = useRef();
  const f_solicitud = moment().format("YYYY-MM-DD");

  const sedes = [
    { value: "Cañete", label: "Cañete" },
    { value: "Chillán", label: "Chillán" },
    { value: "Los Ángeles", label: "Los Ángeles" },
    { value: "Talcahuano", label: "Talcahuano" },
  ];

  const handleChange = (value) => {
    setUA(value.value);
    setSelectedOption(value);
  };

  const changeSede = async (e) => {
    setSelectedOption(null);
    if (e.value != "") {
      const response = await axios
        .get(
          "https://sibucsc.cl/sibucsc/carreras/?facultad=Instituto+Tecnol%C3%B3gico&sede=" +
            e.value,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "ip-address": "10.1.0.4",
            },
          }
        )
        .then((response) => {
          const carreraData = response.data.results.map((item) => ({
            label: item.nombre + " " + "- " + item.jornada,
            value: item.ua,
          }));
          setCarreras(carreraData);
        })
        .catch((err) => {});
    } else {
      setCarreras([]);
    }
  };

  const getApiDataTalleres = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/talleres/index/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const jp = require("jsonpath");
        const listTalleres = jp.query(
          response.data.results,
          "$..[?(@.categoria==999 && @.activo == 1)]"
        );
        setTalleres(listTalleres);
      })
      .catch((err) => {});
  };

  const getApiDataFacultades = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/carreras/facultades/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "ip-address": "10.1.0.4",
        },
      })
      .then((response) => {
        setFacultades(response.data);
      })
      .catch((err) => {});
  };

  const changeCarrera = async (e) => {
    setSelectedOption(null);
    if (e.target.value != "") {
      if (e.target.value === "Instituto Tecnológico") {
        setHidden(false);
        setCarreras([]);
      } else {
        setHidden(true);
        const response = await axios
          .get(
            "https://sibucsc.cl/sibucsc/carreras/?facultad=" + e.target.value,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "ip-address": "10.1.0.4",
              },
            }
          )
          .then((response) => {
            const carreraData = response.data.results.map((item) => ({
              label: item.nombre + " " + "- " + item.jornada,
              value: item.ua,
            }));
            setCarreras(carreraData);
          })
          .catch((err) => {});
      }
    } else {
      setCarreras([]);
      setHidden(true);
    }
  };

  useEffect(() => {
    getApiDataTalleres();
    getApiDataFacultades();
  }, []);

  const handleSubmit = async (e) => {
    // Prevent the default submit and page reload
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      if (ChileanRutify.validRut(rut) === true) {
        // Handle validations
        Swal.fire({
          title: "Enviando consulta",
          html: "Espere por favor",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const loginResponse = await fetch(
            "https://sibucsc.cl/sibucsc/roundtable/token/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username: "gallahad",
                password: "s0p0rt3dti-lancel0t",
              }),
            }
          );

          if (!loginResponse.ok) {
            throw new Error("Login failed");
          }

          const data = await loginResponse.json();

          const response = await axios.post(
            "https://sibucsc.cl/sibucsc/talleres/solicitudes/",
            {
              taller,
              rut,
              f_taller,
              email,
              comentarios,
              ua,
              presencial,
              f_solicitud,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.access,
              },
            }
          );
          if (response.status === 201) {
            Swal.fire(
              "Solicitud enviada con éxito",
              "presione OK para terminar",
              "success"
            ).then(() => {
              window.location.reload();
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al enviar la solicitud, intente nuevamente",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Rut incorrecto, intente nuevamente",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Por favor verifica reCAPTCHA!",
      });
    }
  };

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Solicitud de talleres de Capacitación
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  El Sistema de Bibliotecas pone a disposición de sus usuarios,
                  cinco tipos de talleres que le permitirán desarrollar
                  competencias informacionales al momento de acceder,
                  identificar, evaluar y usar información en forma ética y
                  adecuada a los estándares de investigación.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container fluid className="web-container">
          <Row className="col d-flex justify-content-center m-4">
            <Col xl={6} sm={12} md={12} xs={12}>
              <h5 className="text-[#d12421] font-bold">
                Formulario de solicitud
              </h5>
              <CForm
                className="border p-3"
                action=""
                method="post"
                onSubmit={handleSubmit}
              >
                <CRow className="mb-3 ">
                  {/* INPUT RUT */}
                  <CFormLabel htmlFor="rut" className="col-sm-3 col-form-label">
                    Rut
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="rut"
                      maxLength={9}
                      required
                      value={rut}
                      onChange={(e) => setRut(e.target.value)}
                      placeholder="RUT sin puntos ni guión, Ej: 12345678K"
                    />
                  </CCol>
                  {/* CIERRE INPUT RUT */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT FACULTAD */}
                  <CFormLabel htmlFor="rut" className="col-sm-3 col-form-label">
                    Facultad
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormSelect
                      required
                      onChange={(event) => {
                        changeCarrera(event);
                      }}
                    >
                      <option value="">Seleccione Facultad</option>
                      {Object.entries(facultades).map(([key, fac], i) => (
                        <option key={i} value={fac}>
                          {fac}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  {/* CIERRE INPUT FACULTAD */}
                </CRow>
                {hidden ? null : (
                  <CRow className="mb-3" style={hidden ? "hidden" : ""}>
                    {/* INPUT SEDE */}
                    <CFormLabel
                      htmlFor="rut"
                      className="col-sm-3 col-form-label"
                    >
                      Sede
                    </CFormLabel>
                    <CCol sm={9}>
                      <Select
                        options={sedes}
                        placeholder="Seleccione Sede"
                        onChange={(event) => {
                          changeSede(event);
                        }}
                      />
                    </CCol>
                    {/* CIERRE INPUT CARRERA */}
                  </CRow>
                )}
                <CRow className="mb-3 ">
                  {/* INPUT CARRERA */}
                  <CFormLabel htmlFor="rut" className="col-sm-3 col-form-label">
                    Carrera
                  </CFormLabel>
                  <CCol sm={9}>
                    <Select
                      required
                      options={carreras}
                      value={selectedOption}
                      onChange={handleChange}
                      placeholder="Seleccione Carrera"
                    />
                  </CCol>
                  {/* CIERRE INPUT CARRERA */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT TALLER */}
                  <CFormLabel
                    htmlFor="taller"
                    className="col-sm-3 col-form-label"
                  >
                    Taller
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormSelect
                      required
                      onChange={(e) => setTaller(e.target.value)}
                    >
                      <option value="">Seleccione un Taller</option>
                      {Object.entries(talleres).map(([key, taller], i) => (
                        <option key={i} value={taller.id_taller}>
                          {taller.nombre}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  {/* CIERRE INPUT TALLER */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT MODALIDAD */}
                  <CFormLabel
                    htmlFor="taller"
                    className="col-sm-3 col-form-label"
                  >
                    Tipo de modalidad
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormSelect
                      required
                      onChange={(e) => setPresencial(e.target.value)}
                    >
                      <option value="">Seleccione modalidad</option>
                      <option value="1">Presencial</option>
                      <option value="0">Virtual</option>
                    </CFormSelect>
                  </CCol>
                  {/* CIERRE INPUT MODALIDAD */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT FECHA REALIZACION */}
                  <CFormLabel
                    htmlFor="frealizacion"
                    className="col-sm-3 col-form-label"
                  >
                    Sugerencia fecha de realización
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="date"
                      id="f_taller"
                      required
                      value={f_taller}
                      onChange={(e) => setFtaller(e.target.value)}
                    />
                  </CCol>
                  {/* CIERRE FECHA REALIZACION */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT EMAIL */}
                  <CFormLabel
                    htmlFor="email"
                    className="col-sm-3 col-form-label"
                  >
                    Email
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="email"
                      id="email"
                      required
                      maxLength={250}
                      value={email}
                      placeholder="Ingrese su email aquí"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CCol>
                  {/* CIERRE INPUT EMAIL */}
                </CRow>
                <CRow className="mb-3 ">
                  {/* INPUT COMENTARIOS */}
                  <CFormLabel
                    htmlFor="comentarios"
                    className="col-sm-3 col-form-label"
                  >
                    Comentarios (opcional)
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormTextarea
                      id="comentarios"
                      value={comentarios}
                      maxLength={250}
                      placeholder="Deje sus comentarios aquí (max 250 carácteres)"
                      onChange={(e) => setComentarios(e.target.value)}
                    />
                  </CCol>
                  {/* CIERRE COMENTARIOS */}
                </CRow>
                <CRow>
                  <CCol>
                    <div className="col d-flex justify-content-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={recaptcha}
                      />
                    </div>
                  </CCol>
                </CRow>
                <div className="col d-flex justify-content-center m-3">
                  <Button
                    type="submit"
                    variant="outline-danger"
                    className="btnAnnounc"
                  >
                    Enviar
                  </Button>
                </div>
              </CForm>
            </Col>
            <Col xl={6} sm={12} md={12} xs={12}>
              <h5 className="text-[#d12421] font-bold text-justify mt-3">
                Sobre cada Taller
              </h5>
              <p className="font-bold text-justify">
                Taller 1: Reconocer la necesidad de información
              </p>
              <p>
                Dar a conocer a los usuarios las herramientas y servicios que
                ofrece biblioteca
              </p>
              <p className="font-bold text-justify">
                Taller 2: Acceder a la información
              </p>
              <p>
                Enseñar al usuario estrategias de búsqueda para encontrar la
                información de manera óptima y en el menor tiempo posible.
              </p>
              <p className="font-bold text-justify">
                Taller 3: Evaluar fuentes de información
              </p>
              <p>
                Enseñar al usuario a evaluar la información recopilada según
                criterios preestablecidos, pertinencia y relevancia.
              </p>
              <p className="font-bold text-justify">
                Taller 4: Uso adecuado y ético de la información
              </p>
              <p>
                Enseñar al usuario los tipos de normas de estilos
                correspondientes a su área (APA, ISO o VANCOUVER), y entregarles
                el conocimiento para su correcta citación y redacción.
              </p>
              <p className="font-bold text-justify">
                Taller 5: Gestor Bibliográfico
              </p>
              <p>
                Enseñar al usuario el correcto uso de gestores (EndNote,
                Mendeley o Zotero) para la automatización en la creación de
                citas y referencias bibliográficas.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Workshop;
