import "../../css/Repo.css";
import noteRepo from "../../assets/images/home/repositorio_tesis_01.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Repo = () => {
  return (
    <div className="divRepo" data-tooltip-id="my-tooltip-tesis">
      <a href="http://tesis.ucsc.cl" target="__blank" className="hrefRepo">
        <h4 className="text-center font-bold titleRepo text-[#fff]  ">
          Repositorio de Tesis
        </h4>
        <div className="col d-flex justify-content-center">
          <img src={noteRepo} alt="" className="imgRepo mt-2" />
        </div>
        <div id="descRepos">
          <ReactTooltip
            id="my-tooltip-tesis"
            className="tooltipRepos"
            place="bottom"
            variant="info"
            content="El repositorio es una plataforma electrónica, que tiene como
            objetivo almacenar, preservar, compartir y poner a disposición el
            patrimonio intelectual de los alumnos de pregrado y magíster de la
            Universidad."
          />
        </div>
      </a>
    </div>
  );
};

export default Repo;
