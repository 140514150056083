import "../../css/Repo.css";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { FaDownload } from "react-icons/fa";
import "../../css/HomeInfo.css";
import reglamentos from "../../assets/images/home/reglamentos.png";
import formularioTesis from "../../assets/docs/regulations/formulario-autorizacion-tesis.pdf";
import formatoTesis from "../../assets/docs/regulations/formato-entrega-tesis-digitales.pdf";
import politicaPrestamos from "../../assets/docs/regulations/Politica_prestamos_renovaciones_2023.pdf";
import ReglamentoBiblio from "../../assets/docs/regulations/reglamento-bibliotecas-2014.pdf";

const Regulations = () => {
  return (
    <>
      <div className="col d-flex justify-content-center mt-3">
        <span className="mt-5 titleInfoNormativas font-bold">REGLAMENTOS Y NORMATIVAS</span>
      </div>
      <Row className="m-xl-5 mt-5">
        <Col lg={4} md={2} xs={12} className="d-sm-none d-lg-block d-xl-block">
          <div className="col d-flex justify-content-center">
            <img
              src={reglamentos}
              id="imgReglamentos"
              className="m-xl-3 md-w-100"
            />
          </div>
        </Col>
        <Col lg={4} md={6} xs={12}>
          <div className="col d-flex justify-content-center mt-xs-3 marginCardReg">
            <Card className="cardReglamento">
              <Card.Body>
                <Card.Title>
                  <h5 className="titleReglamento">Reglamento de Bibliotecas</h5>
                </Card.Title>
                <Card.Text className="text-justify">
                  En el reglamento se incluyen un conjunto de normas y
                  directrices que rigen el uso y funcionamiento de los servicios
                  de la biblioteca, en donde quedan establecidos los
                  lineamientos en torno al acceso, la prestación de servicios,
                  deberes de los usuarios y otras disposiciones que involucran
                  la atención en Biblioteca.
                </Card.Text>
                <Card.Text className="text-justify">
                  <Card.Link
                    href={ReglamentoBiblio}
                    target="_blank"
                    className="text-[#DC3545] font-bold"
                  >
                    <FaDownload className="inline" /> Ver aquí
                  </Card.Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col d-flex justify-content-center marginCardReg">
            <Card className="mt-2 cardReglamento">
              <Card.Body>
                <Card.Title>
                  <h5 className="titleReglamento">
                    Políticas de préstamo y renovación
                  </h5>
                </Card.Title>
                <Card.Text className="text-justify">
                  Establece las condiciones y pautas de préstamo y renovación de
                  toda la colección perteneciente al Sistema de Bibliotecas
                  UCSC. Señala las normas sobre la duración del préstamo y los
                  límites en la renovación del material prestado.
                </Card.Text>
                <Card.Text className="text-justify">
                  <Card.Link
                    href={politicaPrestamos}
                    target="_blank"
                    className="text-[#DC3545] font-bold"
                  >
                    <FaDownload className="inline" /> Ver aquí
                  </Card.Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col lg={4} md={6} xs={12}>
          <div className="col d-flex justify-content-center">
            {" "}
            <Card className="mt-2 cardReglamento">
              <Card.Body>
                <Card.Title>
                  <h5 className="titleReglamento">
                    Guía para la elaboración de tesis digitales
                  </h5>
                </Card.Title>
                <Card.Text className="text-justify">
                  Es un documento que proporciona instrucciones detalladas sobre
                  cómo estructurar, redactar y presentar una tesis en formato
                  digital. Incluye pautas y recomendaciones respecto al diseño,
                  contenido y estructura bibliográfica.
                </Card.Text>
                <Card.Text className="text-justify">
                  <Card.Link
                    href={formatoTesis}
                    target="_blank"
                    className="text-[#DC3545] font-bold"
                  >
                    <FaDownload className="inline" /> Ver aquí
                  </Card.Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col d-flex justify-content-center">
            <Card className="mt-2 mt-xl-4 cardReglamento">
              <Card.Body>
                <Card.Title>
                  <h5 className="titleReglamento">
                    Formulario de autorización para la Publicación de Tesis
                    Digitales
                  </h5>
                </Card.Title>
                <Card.Text className="text-justify">
                  Este documento debe ser completado por él o los autores de la
                  tesis, otorgando la correspondiente autorización para la
                  publicación del trabajo en el repositorio de Tesis de la
                  Universidad.
                </Card.Text>
                <Card.Text className="text-justify">
                  <Card.Link
                    href={formularioTesis}
                    target="_blank"
                    className="text-[#DC3545] font-bold"
                  >
                    <FaDownload className="inline" /> Ver aquí
                  </Card.Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Regulations;
